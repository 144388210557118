<template>

    <div id="main" class="id" ref="main"></div>
</template>

<script setup>
import * as echarts from 'echarts'
import { onMounted, ref, watch, watchEffect } from 'vue'

// const inputVal = ref('')
const props = defineProps({
    listX: Array,
    listY: Array,
    title: String
})

// console.log(props.listX)
let options
let main = ref()
let myChart
let timer = null
const myChartInit = () => {
    console.log(props.listX.value)
    console.log(props.listY)
    myChart = echarts.init(main.value)
    options = {
        color: ['#0bc0c4'],
        textStyle: {
            color: '#fff'
        },
        tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(0,0,0,.1)',
            textStyle: {
                color: '#ffffff',
                fontSize: 18,
                fontWeight: 'normal'
            },
            axisPointer: {
                type: 'line',
                label: {
                    fontSize: 16,
                    show: true,
                    backgroundColor: '#7B7DDC'
                }
            }
        },
        xAxis: {
            type: 'category',
            axisTick: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    color: '#ccc'
                }
            },
            data: props.listX.value
        },
        yAxis: {
            type: 'value',
            name: '元',
            alignTicks: true,
            splitLine: false,
            axisLine: {
                lineStyle: {
                    color: '#ccc'
                }
            }
        },
        series: [
            {
                data: props.listY.value,
                type: 'line',
                smooth: true,
                symbolSize: 10,
                areaStyle: {
                    //折线图颜色半透明
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: 'rgba(134, 251, 192, 0.5)' // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: 'rgba(134, 251, 192, 0.1)' // 100% 处的颜色
                            }
                        ],
                        global: false // 缺省为 false
                    }
                }
            }
        ]
    }
    let app = {
        currentIndex: -1
    }

    // let change = options.series[0].data.length
    timer = setInterval(function () {
        let dataLen = options.series[0].data.length
        // console.log(dataLen)
        // 取消之前高亮的图形
        myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: app.currentIndex
        })
        app.currentIndex = (app.currentIndex + 1) % dataLen
        // console.log(app.currentIndex)
        // 高亮当前图形
        myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: app.currentIndex
        })
        // 显示 tooltip
        myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: app.currentIndex
        })
    }, 3000)

    if (options && typeof options === 'object') {
        myChart.setOption(options, true)
    }

    window.onresize = function () {
        myChart.resize()
    }
    myChart.setOption(options)
}

const clear = () => {
    myChart.clear()
    clearInterval(timer)
}
defineExpose({
    myChartInit,
    clear
})
// onMounted(() => {
//     myChartInit()
// }
// )
</script>

<style scoped>
.id {
    width: 100%;
    height: 100%;
}

.clearfix {
    position: relative;
    width: 100%;
    /* height: 100%; */
}

.select_time {
    width: 210px;
    /* border-radius: 5px; */
    float: right;
    background: #1e3d64;
    left: 74%;
    top: 221%;
    position: absolute;
    z-index: 11;
}

.select_time span {
    display: block;
    width: 70px;
    height: 35px;
    float: left;
    /* background: #4095e5; */
    text-align: center;
    line-height: 35px;
    color: #1677df;
}

.select_time .active {
    background-color: #4c9bfd;
    color: #74f8fd;
}
</style>
